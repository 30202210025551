// Import dependencies
import React from "react";
import {Button, Drawer, Menu, Divider} from "antd";
import { navigate, Link } from "gatsby"

// Import components
import GeneralButton from "../form/buttons/GeneralButton";
import {ParagraphText} from "../../styled-components/UILibrary";
import CartItem from "./cartItem";
import Binoculars from "../../assets/images/binoculars.png"
import DinoButton from "../../assets/icons/dino-button.svg";


// Import styles
import "./cart.css";
import {Constants} from "../../constants/Constants";

// Import redux
import {connect} from "react-redux";
import GeneralCartItem from "./genCartItem";

/*
    Shopping Cart component
*/
const DrawerDynamicContent = ({store, showCart, styles, arrayContent, title, isBrand, brandName, isCategory, showItemsLength, itemClick, brandDepartment}) => {

  const navigateItem = (linkContent, i) => {
    if (isBrand) {
      navigate("/brand/" + linkContent.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s/g, ''));
    }
    if (isCategory) {
      const productName = "OTHER " + linkContent;
      const productInfo =   Object.values(store.allProductsById)
        .find((product) => {
          return product.Product == productName;
        });
      if (productInfo) {
        const productId = productInfo.ProductID;
        navigate("/quoteOther/", {
            state: {
              productId: productId,
              productName: productName,
              productInfo: productInfo
            },
        });
      } else {
        navigate("/quoteOther/", {
            state: {
              productName: productName,
            },
        });
      }
    }
    if (showItemsLength) {
      itemClick(i);
      showCart();
    }
  }

  const renderBrandItem = (brandItem, i) => {
      let items = showItemsLength ? brandItem.items : '';
      brandItem = showItemsLength ? brandItem.title.toString() : brandItem.toString();
      return (
          <Menu.Item
            key={i}
            onClick={() => navigateItem(brandItem, i)}
            role
          >
              <div className="category-drawer__menu-item">
                  <div className="category-drawer__menu-item-name">
                    {brandItem}
                    {((showItemsLength && brandDepartment != "Books" && brandDepartment != "Cookware" && brandDepartment !="Clothes") && items.length > 0) ?
                      " (" + (parseInt(items.length)+1) + ")"
                      : " (1)"
                    }
                  </div>
              </div>
          </Menu.Item>
      )
  }

    return (
      <div class={styles} >
        <div className="cart__background"></div>
        <div className="cart__container">
          <div className="category-drawer__container-top-label category-drawer__container-top-label-department-level">
            <div className="category-drawer__container-top-label-text">{title}</div>
          </div>
          <div class="category-drawer__container-close-button" onClick={showCart}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.4 0.613295C16.88 0.0932946 16.04 0.0932946 15.52 0.613295L8.99996 7.11996L2.47996 0.599961C1.95996 0.079961 1.11996 0.079961 0.599961 0.599961C0.079961 1.11996 0.079961 1.95996 0.599961 2.47996L7.11996 8.99996L0.599961 15.52C0.079961 16.04 0.079961 16.88 0.599961 17.4C1.11996 17.92 1.95996 17.92 2.47996 17.4L8.99996 10.88L15.52 17.4C16.04 17.92 16.88 17.92 17.4 17.4C17.92 16.88 17.92 16.04 17.4 15.52L10.88 8.99996L17.4 2.47996C17.9066 1.97329 17.9066 1.11996 17.4 0.613295V0.613295Z" fill="#828282"></path></svg>
          </div>
          <div class="category-drawer__container-top-label-divider" style={{height: 1}}>
            <div class="ant-divider ant-divider-horizontal" role="separator" style={{margin: 0}}></div>
          </div>
          <Menu role selectable={true} style={isBrand ? {overflow: "auto", marginBottom: 58} : {overflow: "auto"}}>
                <Menu.ItemGroup>
                    { arrayContent.map((brand, i) => {
                        return renderBrandItem(brand, i)
                    })}
                    {
                      isBrand &&
                      <li className="item_not_found brand_menu" onClick={() => navigate("/department/" + brandName + "/brand-not-found")}>
                        <DinoButton />
                        <span>My brand isn’t here</span>
                      </li>

                    }
                </Menu.ItemGroup>
          </Menu>
        </div>
      </div>
    );
};

// Connect redux to component
function readProductsList(storeState) {
    let store = {
        allProductsById: storeState.product.productsIndexedById,
    };
    return {store};
}

export default connect(readProductsList)(DrawerDynamicContent);
