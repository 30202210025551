import React from "react"
import {useStaticQuery, graphql, StaticQuery} from "gatsby"
import Img from "gatsby-image"
import GalleriaBackground from "../../assets/images/galleria_background.png"

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename);
      });
      if (props.imgtype == "brand") {
        return <div className="item-background-img item-background-img-absolute" style={{backgroundImage: "url('/" + props.filename + "')"}}></div>;
      } 
      if (!image) {
        return null;
      }
      //console.log(image.node.childImageSharp.fluid);
      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      //  return <Img alt={props.alt} fluid={image.node.childImageSharp.fluid} />;
      if (props.imgtype == "logo") {
        return <img src={image.node.childImageSharp.fluid.src} class="brand_logo_img"/>;
      } else if (props.imgtype == "challenge") {
        return <img src={image.node.childImageSharp.fluid.src} class="brand_logo_img challenge_logo_img"/>;
      } else if (props.imgtype == "brand") {
        return <div className="item-background-img item-background-img-absolute" style={{backgroundImage: "url('/" + props.filename + "')"}}></div>;
      } else if (props.imgtype == "galleria") {
        return <div className="brand_item_container">
          <img src={GalleriaBackground} className="galleria_background"/>
          <img src={image.node.childImageSharp.fluid.src} className="galleria_brand_logo"/>
        </div>
      } else if (props.imgtype == "logo_background") {
        return <div style={{backgroundImage: "url('" + image.node.childImageSharp.fluid.src + ""}} class="other_brand_logo"></div>
      } else {
        return <img src={image.node.childImageSharp.fluid.src} class="brand_tile_img"/>;
      }

    }}
  />
);

export default Image;
